(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("react"), require("react-redux"), require("@carto/react-redux"), require("@carto/react-ui"), require("@carto/react-core"), require("@carto/react-workers"), require("@carto/react-api"), require("@carto/react-core/"), require("@carto/react-redux/"), require("@material-ui/core"), require("@material-ui/core/styles"));
	else if(typeof define === 'function' && define.amd)
		define(["react", "react-redux", "@carto/react-redux", "@carto/react-ui", "@carto/react-core", "@carto/react-workers", "@carto/react-api", "@carto/react-core/", "@carto/react-redux/", "@material-ui/core", "@material-ui/core/styles"], factory);
	else if(typeof exports === 'object')
		exports["cartoReactWidgets"] = factory(require("react"), require("react-redux"), require("@carto/react-redux"), require("@carto/react-ui"), require("@carto/react-core"), require("@carto/react-workers"), require("@carto/react-api"), require("@carto/react-core/"), require("@carto/react-redux/"), require("@material-ui/core"), require("@material-ui/core/styles"));
	else
		root["cartoReactWidgets"] = factory(root["react"], root["react-redux"], root["@carto/react-redux"], root["@carto/react-ui"], root["@carto/react-core"], root["@carto/react-workers"], root["@carto/react-api"], root["@carto/react-core/"], root["@carto/react-redux/"], root["@material-ui/core"], root["@material-ui/core/styles"]);
})(self, function(__WEBPACK_EXTERNAL_MODULE__156__, __WEBPACK_EXTERNAL_MODULE__756__, __WEBPACK_EXTERNAL_MODULE__347__, __WEBPACK_EXTERNAL_MODULE__214__, __WEBPACK_EXTERNAL_MODULE__485__, __WEBPACK_EXTERNAL_MODULE__116__, __WEBPACK_EXTERNAL_MODULE__178__, __WEBPACK_EXTERNAL_MODULE__38__, __WEBPACK_EXTERNAL_MODULE__987__, __WEBPACK_EXTERNAL_MODULE__670__, __WEBPACK_EXTERNAL_MODULE__793__) {
return 